// src/components/HeroSection.js
import React, { useEffect, useState } from 'react';
import './HeroSection.css';

const images = [
  { src: '130ave_f_bayonne/bird-1600.webp', alt: '130 Ave F 1' },
  { src: '130ave_f_bayonne/front-1600.webp', alt: '130 Ave F 2' },
  { src: '130ave_f_bayonne/top-1600.webp', alt: '130 Ave F 3' },
];
const titles = ["Welcome to RIVCON", "Your Project, Our Priority", "Building Excellence, Every Time"];
const subtitles = ["Building Dreams, Creating Reality", "Constructing with Precision", "Where Quality Meets Reliability"];

export default function HeroSection() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [textAnimation, setTextAnimation] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextAnimation(true);
      setTimeout(() => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
        setTextAnimation(false);
      }, 1500);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImageIndex]);

  return (
    <section
      className="hero is-fullheight hero-background"
      style={{ backgroundImage: `url(${images[currentImageIndex].src})` }}
    >
      <div className="hero-body">
        <div className="overlay"></div>
        <div className="container has-text-centered">
          <div className={textAnimation ? 'fade-in' : ''}>
            <h1 className="title center-text">{titles[currentImageIndex]}</h1>
            <h2 className="subtitle center-text">{subtitles[currentImageIndex]}</h2>
          </div>
        </div>
      </div>
    </section>
  );
}
