// src/components/Modal.js
import React, { useState } from 'react';
import './Modal.css';

export default function Modal({ project, closeModal }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = project.images;

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="modal-container">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-content">
        <div className="image-container">
          <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1} of ${project.title}`} />
          {/* Clickable areas on left and right sides */}
          <div className="clickable-area left" onClick={showPreviousImage}></div>
          <div className="clickable-area right" onClick={showNextImage}></div>
          {/* Arrows */}
          <span className="arrow left-arrow" onClick={showPreviousImage}>&#10094;</span>
          <span className="arrow right-arrow" onClick={showNextImage}>&#10095;</span>
        </div>
      </div>
      <button className="modal-close" aria-label="close" onClick={closeModal}></button>
    </div>
  );
}