// src/components/Gallery.js
import React, { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import './Gallery.css';

const projects = [
  { id: 1, title: '669 Bergen Ave. Jersey City, NJ', 
    images: [
        '669bergen_ave_jersey_city/complete.png', 
        '669bergen_ave_jersey_city/complete_profile.png',
        "669bergen_ave_jersey_city/sidewalk.png", 
        '669bergen_ave_jersey_city/start_time.png',
        "669bergen_ave_jersey_city/start2_time.png", 
        "669bergen_ave_jersey_city/framing_street_time.png",
        "669bergen_ave_jersey_city/concrete_time.png",
        "669bergen_ave_jersey_city/lobby.png" 
  ] },
  { id: 2, title: '130 Ave F. Bayonne, NJ', 
    images: [
        "130ave_f_bayonne/MHS_architecture_render.webp",
        "130ave_f_bayonne/bird-1600.webp", 
        "130ave_f_bayonne/front-1600.webp", 
        "130ave_f_bayonne/top-1600.webp",
        "130ave_f_bayonne/front_bird_dusk.webp",
        "130ave_f_bayonne/back_bird_dusk.webp"
    ] },
  { id: 3, title: '176 West Side Ave. Jersey City, NJ', 
    images: [
        "176west_side_jersey_city/176_west_side_render_enh.webp",
        "176west_side_jersey_city/176_west_side_north_elevation.webp",
        "176west_side_jersey_city/176_west_side_south_elevation.webp",
        "176west_side_jersey_city/176_west_side_east_west_elevation.webp"
    ] },
  { id: 4, title: '99 Storms Ave. Jersey City, NJ', 
    images: [
        "99storms_ave_jersey_city/bird.png",
        "99storms_ave_jersey_city/bird_cityview.png",
        "99storms_ave_jersey_city/finished_hallway_time.png"
    ] },
  { id: 5, title: '803 South Ave. Plainfield, NJ', 
    images: [
        "803south_ave_plainfield/803south_ave_plainfield_drone_front.webp",
        "803south_ave_plainfield/803south_ave_plainfield_drone_pool.webp",
        "803south_ave_plainfield/803south_ave_plainfield_render.webp",
        "803south_ave_plainfield/interior_lobby.webp",
        "803south_ave_plainfield/interior_unit.webp"
    ] },
  { id: 6, title: '175 West 7th Ave. Bayonne, NJ', 
    images: [
        "175west_7th_bayonne/175west_7th_drone.webp",
        "175west_7th_bayonne/175west_7th_street.webp",
        "175west_7th_bayonne/175west_7th_render.webp",
        "175west_7th_bayonne/175west_7th_render_dusk.webp"
    ] },
  { id: 7, title: '62 69th St. Guttenberg, NJ', 
    images: [
        "62_69th_st_guttenberg/complete_street.webp",
        "62_69th_st_guttenberg/complete_bird.webp",
        "62_69th_st_guttenberg/render_street_night.webp",
        "62_69th_st_guttenberg/render_bird_night.webp"
    ] }
  // Add more projects as needed
];

export default function Gallery() {
    const [selectedProject, setSelectedProject] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.1 }
      );
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, []);
  
    const openModal = (project) => {
      setSelectedProject(project);
    };
  
    const closeModal = () => {
      setSelectedProject(null);
    };
  
    return (
      <section ref={ref} className={`gallery ${isVisible ? 'fade-in' : ''}`}>
        <h2 className="gallery-title">Our Projects</h2>
        <div className="gallery-grid">
          {projects.map((project) => (
            <div key={project.id} className="gallery-item" onClick={() => openModal(project)}>
              <img src={project.images[0]} alt={project.title} />
              <div className="gallery-title">{project.title}</div>
            </div>
          ))}
        </div>
        {selectedProject && <Modal project={selectedProject} closeModal={closeModal} />}
      </section>
    );
  }