// src/components/MissionStatement.js
import React, { useState, useEffect, useRef } from 'react';
import './MissionStatement.css';

export default function MissionStatement() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <section ref={ref} className={`mission-statement ${isVisible ? 'fade-in' : ''}`}>
      <h2>Our Mission</h2>
      <p>
        At <span className="highlight">RIVCON</span>, our mission is to deliver&nbsp;
        <span className="highlight">high-quality</span>,&nbsp;
        <span className="highlight">sustainable</span>,
        and <span className="highlight">innovative</span>&nbsp;
        construction solutions that meet the unique needs of our clients.&nbsp;
        We are committed to building lasting relationships through&nbsp;
        <span className="highlight">integrity</span>,&nbsp;
        <span className="highlight">safety</span>, and&nbsp;
        <span className="highlight">excellence</span>,&nbsp;
        ensuring every project is completed on time, within budget,&nbsp;
        and to the highest standards of craftsmanship
      </p>
    </section>
  );
}
