// src/components/ContactPage.js
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactPage.css';

export default function ContactPage() {
  const [formStatus, setFormStatus] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setFormStatus('Please complete the CAPTCHA.');
      return;
    }

    const form = e.target;
    const formData = new FormData(form);

    const response = await fetch('https://formspree.io/f/mwpkbnwb', {
      method: 'POST',
      body: formData,
      headers: { 'Accept': 'application/json' },
    });

    if (response.ok) {
      setFormStatus('success');
      form.reset();
      setCaptchaValue(null);
    } else {
      setFormStatus('error');
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <section className="contact-page">

      {/* Image Section */}
      <div className="image-section" style={{ backgroundImage: `url('669bergen_ave_jersey_city/sidewalk.png')` }}></div>

      {/* Contact Form Section */}
      <div className="section contact-section">
        <div className="container">
          <h1 className="title has-text-centered">Contact Us</h1>
          <p className="subtitle has-text-centered ">We’d love to hear from you. Please fill out the form below:</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input className="input" type="text" name="name" placeholder="Your Name" required />
              </div>
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input className="input" type="email" name="email" placeholder="Your Email" required />
              </div>
            </div>
            <div className="field">
              <label className="label">Message</label>
              <div className="control">
                <textarea className="textarea" name="message" placeholder="Your Message" required></textarea>
              </div>
            </div>
            
            {/* reCAPTCHA */}
            <div className="field">
              <ReCAPTCHA
                sitekey="6LeolHgqAAAAAADdbX5yGdsRGHfMP7R1vWXYkfEL"
                onChange={onCaptchaChange}
              />
            </div>

            <div className="control">
              <button className="button is-primary is-fullwidth">Send Message</button>
            </div>
          </form>

          {/* Feedback message for form submission */}
          {formStatus === 'success' && (
            <div className="notification is-success mt-4">
              Thank you! Your message has been sent successfully.
            </div>
          )}
          {formStatus === 'error' && (
            <div className="notification is-danger mt-4">
              Oops! Something went wrong. Please try again.
            </div>
          )}
          {formStatus === 'Please complete the CAPTCHA.' && (
            <div className="notification is-warning mt-4">
              {formStatus}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
