// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
        {/* Logo box in the top-left corner */}
        <div className="logo-box">
          <img src="rivcon_logo_white.png" alt="Rivcon Logo" className="logo" />
        </div>
        {/* <img src="rivcon_logo_cropped_v2.png" alt="Rivcon Banner" className="banner" /> */}

        {/* Animated Menu Button in the Top-Right Corner */}
        <div className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
          <div className={`hamburger ${menuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          {menuOpen && (
            <div className="menu-dropdown">
              <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
              <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact Us</Link>
            </div>
          )}
        </div>
    </header>
  );
}